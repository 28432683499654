<template>
  <DefaultCrudView :breadcrumbsItems="breadcrumbs">

    <template slot="content">
      <v-container fill-height fluid>
        <v-card
          :width="cardWidth"
          class="mx-auto"
          raised
          dark
        >
          <v-card-title>{{ $t('unomi_console') }}</v-card-title>

          <v-card-subtitle>
            {{ $t('console_unomi_subtitle') }} <a :href="docURL" target="_blank">{{ $t('here') }}.</a>
          </v-card-subtitle>

          <v-card-text
            ref="output"
            class="output-content"
          >
            <v-list nav>
              <pre>
                <v-list-item
                  v-for="(content, index) in outputList"
                  :key="index"
                >
                  <v-list-item-content 
                    v-text="content"
                  ></v-list-item-content>
                </v-list-item>
              </pre>
            </v-list>
          </v-card-text>

          <v-form 
            @submit.prevent="submit"
          >
            <v-card-actions>
            
              <v-text-field 
                :loading="sending"
                :disabled="sending"
                v-model="inputVal"
                prefix="unomi:"
                color="success"
                autofocus
              ></v-text-field>

              <v-btn
                :loading="sending"
                :disabled="sending"
                class="mx-2"
                type="submit"
                fab
                light
                small
              >
                <v-icon dark>
                  mdi-send
                </v-icon>
              </v-btn>
              
            </v-card-actions>
          </v-form>
        </v-card>
      </v-container>
    </template>

  </DefaultCrudView>
</template>

<style scoped>
  >>>.output-content {
    height: 600px;
    overflow: auto;
  }
</style>

<script>

import { mapState, mapActions } from 'vuex'

import DefaultCrudView from '@/components/DefaultCrudView.vue'

import UnomiConsoleActions from '@/store/cdp/unomi_console/actions-types'

export default {
  name: "UnomiConsole",

  components: {
    DefaultCrudView,
  },

  data() {
    return {
      sending: false,
      inputVal: null,
    }
  },

  computed: {
    ...mapState({
      outputList: state => state.cdp.unomi_console.outputList,
    }),

    docURL() {
      return process.env.VUE_APP_UNOMI_SHELL_COMMANDS_DOC
    },
    
    cardWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'md':
        case 'lg':
        case 'xl':
          return "80%"
        default: return "100%"
      }
    },

    breadcrumbs() {
      return [
        {
          text: this.$t('cdp_settings'),
          prependIcon: 'mdi-cog-outline',
          iconColor: 'primary',
          to: {
            name: "CdpSettings",
            params: { appId: this.$route.params.appId }
          },
          disabled: false,
          exact: true,
          ripple: true,
        },
        {
          text: this.$t('unomi_console'),
        },
      ]
    }
  },

  methods: {
    ...mapActions('cdp/unomi_console', [
      UnomiConsoleActions.SEND_COMMAND,
    ]),

    submit() {
      this.sending = true

      this.[UnomiConsoleActions.SEND_COMMAND](this.inputVal)
        .then(() => {
          this.$refs.output.scroll({
            top: this.$refs.output.scrollHeight,
            left: 0,
            behavior: 'smooth'
          })

          this.inputVal = null
          this.sending = false
        })
        .catch(() => this.sending = false)
    },
  },
}

</script>
